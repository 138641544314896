(function() {
  if (typeof window.Event === "function") return false;

  function Event(event, params) {
    params = params || { bubbles: false, cancelable: false, detail: undefined };
    var evt = document.createEvent("Event");
    evt.initEvent(event, params.bubbles, params.cancelable, params.detail);
    return evt;
  }

  Event.prototype = window.Event.prototype;

  window.Event = Event;
})();

(function() {
  if (typeof window.CustomEvent === "function") return false;

  function CustomEvent(event, params) {
    params = params || { bubbles: false, cancelable: false, detail: undefined };
    var evt = document.createEvent("CustomEvent");
    evt.initCustomEvent(
      event,
      params.bubbles,
      params.cancelable,
      params.detail
    );
    return evt;
  }

  CustomEvent.prototype = window.Event.prototype;

  window.CustomEvent = CustomEvent;
})();
